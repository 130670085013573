import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import moment from 'moment'

import Layout from '../components/layout'

const SecondPage = ({ data }) => {
  const platforms = platform => {
    data.allImageSharp.edges.map(p => {
      // console.log(p.node.fixed.originalName.split('-')[0])
      if (p.node.fixed.originalName.split('-')[0] === platform) {
        // console.log('match')
        // console.log(p.node.fixed.src)
        return <img src={p.node.fixed.src} alt={platform} />
      }
      return null
    })
  }

  const games = () => {
    const gdata = year => {
      return data.allDataJson.edges[0].node.games
        .filter(t => {
          return moment.utc(t.datefinish).isSame(moment(year, 'YYYY'), 'year')
        })
        .sort(function (a, b) {
          const af = moment.utc(a.datefinish)
          const bf = moment.utc(b.datefinish)
          return bf - af
        })
    }
    const years = () => {
      return Array.from(
        new Set(
          data.allDataJson.edges[0].node.games.map(t => {
            return moment.utc(t.datefinish).year()
          })
        )
      ).sort((a, b) => b - a)
    }

    return (
      <div>
        {years().map(year => (
          <div key={year}>
            <h1>{year}</h1>
            <ul>
              {gdata(year).map(game => (
                <li key={game.name}>
                  <img
                    src={game.imgsrc}
                    alt={game.name}
                    title={game.name + '\n' + game.datefinish}
                  />
                  {platforms(game.platform)}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    )
  }
  return (
    <Layout>
      <div className='gamelist'>{games()}</div>

      <Link to='/'>Go back to the homepage</Link>
    </Layout>
  )
}
export default SecondPage

export const query = graphql`
  {
    allDataJson {
      edges {
        node {
          games {
            name
            imgsrc
            link
            datestart
            datefinish
          }
        }
      }
    }
    allImageSharp {
      edges {
        node {
          ... on ImageSharp {
            fixed(width: 24) {
              src
              originalName
            }
          }
        }
      }
    }
  }
`
